<template>
  <div>
    <div class="header pb-8">
      <div class="">
        <div class="header-body">
          <div class="row mb-3">
            <div class="col">
              <h4 class="text-center text-uppercase">
                REPORTE DE ENTIDAD {{ dateVerbose }}
              </h4>
              <app-ctrl-year-month
                class="d-print-none"
                ref="elCtrl"
                v-model="date"
                @input="getUtilities()"
              ></app-ctrl-year-month>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 col-lg-6" v-for="(l, i) in list" :key="i">
              <div class="card card-stats mb-4 mb-xl-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h3 class="card-title text-uppercase text-muted mb-0">
                        {{ l.name }}
                      </h3>
                      <div class="h4 font-weight-bold">
                        <app-span-money
                          :moneyCode="1"
                          :quantity="l.utility"
                        ></app-span-money>
                      </div>
                      <div class="text-info h4">
                        <i class="fas fa-arrow-alt-circle-right"></i>
                        <app-span-money
                          :moneyCode="1"
                          class="ml-1"
                          :quantity="l.inputs"
                        ></app-span-money>
                      </div>
                      <div class="text-danger h4">
                        <i class="fas fa-arrow-alt-circle-left"></i>
                        <app-span-money
                          :moneyCode="1"
                          class="ml-1"
                          :quantity="l.outputs"
                        ></app-span-money>
                      </div>
                    </div>
                    <!-- <div class="col-auto">
                      <div
                        class="icon icon-shape bg-danger text-white rounded-circle shadow"
                      >
                        <i class="fas fa-chart-bar"></i>
                      </div>
                    </div> -->
                  </div>
                  <p class="mt-3 mb-0 text-muted text-sm">
                    <span
                      class=" mr-2"
                      :class="{
                        'text-success': l.evolution > 0,
                        'text-danger': l.evolution <= 0
                      }"
                    >
                      <i
                        class="fa "
                        :class="{
                          'fa-arrow-up': l.evolution > 0,
                          'fa-arrow-down': l.evolution <= 0
                        }"
                      ></i>
                      {{ l.evolution | percent }}
                    </span>
                    <span class="text-nowrap">Crecimiento</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-2">
            <div class="col">
              <h4 class="text-right">
                TOTAL
                <app-span-money
                  :moneyCode="1"
                  :quantity="total"
                ></app-span-money>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { AdminService } from "../AdminService";
import { myMoment } from "../../utils/myMoment";
// import { myMoment } from "../utils/myMoment";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    date: {},
    list: []
  }),

  computed: {
    ...mapState({
      config: s => s.config.dental.proform.days_to_init
    }),
    total() {
      return this.list.reduce((p, c) => p + parseFloat(c.utility || 0), 0);
    },
    dateVerbose() {
      return myMoment(`${this.date.year}-${this.date.month}`, "YYYY-MM").format(
        "MMMM YYYY"
      );
    }
  },

  watch: {
    //
  },

  mounted() {
    // this.getUtilities();
  },

  methods: {
    getUtilities() {
      AdminService.getEntityUtilities({
        // year: myMoment().year(),
        // month: myMoment().month() + 1
        ...this.$refs.elCtrl.getValue()
      }).then(list => {
        this.list = list;
      });
    }
  }
};
</script>

<style></style>
